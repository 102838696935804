
export const toMoney = (value) => {
  if (value == null) return 0;
  const parts = parseFloat(value).toFixed(2).split('.')
  return parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + (parts[1] ? `.${parts[1]}` : '')
}

export const toNum = (value) => {
  if (value == null) return 0;
  return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const show_page = (perPage, currentPage) => {
  return (perPage * (currentPage - 1)+1)
}

export const show_page_to = (perPage, currentPage, recordsTotal) => {
  return (perPage * (currentPage - 1)) + recordsTotal
}

export default {
  toMoney,
  toNum,
  show_page,
  show_page_to,
}
